import React, { useCallback, useEffect, useMemo, Fragment } from 'react';
import Head from 'next/head';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Grid } from 'antd';
import { useRouter } from 'next/router';
import pathToRegexp from 'path-to-regexp';
import cloneDeep from 'lodash/cloneDeep';
import SiderMenu from 'src/components/SiderMenu';
import GlobalHeader from 'src/components/GlobalHeader';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { appReadySelector, isInAppSelector, localeSelector, isGroupUserSelector } from 'src/selector/app';
import { currentUserSelector, menusSelector } from 'src/selector/user';
import { collapsedSelector } from 'src/selector/app';
import { restauantProfileSelector } from 'src/selector/setting';
import { fetchRestaurantProfile } from 'src/actions/setting';
import { actions as appActions, fetchRestaurantList } from 'src/actions/app';
import { fetchCurrentEmployeeInfo, loadMenus, loadPermissionCodes } from 'src/actions/user';
import Fonts from 'src/third_party/Fonts';
import { DISTRIBUTION_MODES } from 'src/consts';
import { getItemName } from 'src/utils/utils';
import { MENU, MENU_SETUP } from 'src/consts/permissionCodes';
import { checkNavMenuVisible } from 'src/utils/user';
import { subscribeRestaurantChannel } from 'src/third_party/pusher';
import { IDENTITY_RESTAURATEUR } from 'src/consts/user';
import { PageMenuSetup, PageOnlineOrderingSettings } from 'src/consts/routes';
import { mixpanel } from '../third_party/Mixpanel';
import AdminWaterMarker from 'src/components/AdminWaterMarker';
import styles from './basic.less';
import useWatermarkConfig from 'src/hooks/useWatermarkConfig';
import { fetchAMWhitelist } from 'src/actions/appConfig';

const { Content } = Layout;
const { useBreakpoint } = Grid;
const SmallLogo = `${process.env.ASSETS_PREFIX}/assets/logo_sm.png`;
const logo = `${process.env.ASSETS_PREFIX}/assets/logo.png`;
const needExtractMatchUrlPattern = [PageOnlineOrderingSettings];

const getPageTitle = (pathname = '/dashboard', locale, menus) => {
  const menu = menus.find(
    (menuItem) => (!!menuItem.path && pathToRegexp(menuItem.path).test(pathname)) || pathname.includes(menuItem.path)
  );

  let _menu = menu;
  const extractMathPathname = needExtractMatchUrlPattern.find((path) => pathname.includes(path));
  if (menu) {
    const { children } = menu;
    if (children && children.length) {
      const subMenu = children.find((menuItem) => {
        if (!menuItem.path) return false;
        if (extractMathPathname) return menuItem.path === extractMathPathname;
        return pathname.includes(menuItem.path);
      });
      if (subMenu) _menu = subMenu;
    }
  }

  if (_menu) {
    return `POS-` + getItemName(_menu, locale);
  }

  return 'POS';
};

const BasicLayout = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const locale = useSelector(localeSelector);
  const { menus, loadingMenu } = useSelector(menusSelector);
  const { restaurantInfo, settings } = useSelector(restauantProfileSelector);
  const { currentUser, currentRestaurantId, identity } = useSelector(currentUserSelector);
  const isGroup = useSelector(isGroupUserSelector);
  const isInApp = useSelector(isInAppSelector);
  const appReady = useSelector(appReadySelector);
  const collapsed = useSelector(collapsedSelector);
  const { fixedSideBar = true, sideBarWidth = 240, layoutBackgroundColor = '#f5f5f5' } = props;
  const watermarkConfig = useWatermarkConfig();

  useEffect(() => {
    if (!appReady || !currentRestaurantId || isGroup) return;

    dispatch(loadPermissionCodes());
    dispatch(fetchRestaurantProfile(true));
    dispatch(fetchAMWhitelist());

    if (!isInApp) {
      dispatch(fetchRestaurantList());
      dispatch(loadMenus());
      // subscribe restaurant channel, if is subscribed will do nothing
      subscribeRestaurantChannel(currentRestaurantId);
    }
  }, [appReady, isInApp, currentRestaurantId, isGroup]);

  useEffect(() => {
    if (!appReady || !currentUser || !currentUser.sub) return;
    const { restaurant_contracts = [] } = restaurantInfo || {};
    if (!restaurant_contracts.find((_) => _.distribution_mode === DISTRIBUTION_MODES.POS)) return;
    if (identity === IDENTITY_RESTAURATEUR) {
      dispatch(fetchCurrentEmployeeInfo(currentUser.sub));
    }
  }, [currentUser, appReady, restaurantInfo.restaurant_contracts, identity]);

  useEffect(() => {
    if (settings) {
      mixpanel.toggleTracking(settings.enableMixpanelTracking);
    }
  }, [settings]);

  const filteredMenus = useMemo(() => {
    const cloneMenus = cloneDeep(menus);
    return cloneMenus.reduce((acc, menu) => {
      const visible = checkNavMenuVisible({ menu, restaurantInfo });
      if (!visible) return acc;

      const { children } = menu;
      if (children && children.length) {
        for (let i = children.length - 1; i >= 0; i--) {
          const subMenu = children[i];
          const visible = checkNavMenuVisible({ menu: subMenu, restaurantInfo });
          if (!visible) children.splice(i, 1);
        }
        // 如果所有二级菜单均不可见，则一级菜单也不显示
        if (!children.length) return acc;
      }

      // 菜单管理特殊逻辑 TODO 待旧版菜单管理页面废弃后，可移除此特殊逻辑
      if (menu.permission_code === MENU) {
        menu.children.push({
          name: 'Menu Setup',
          foreign_name: '菜单设置',
          permission_code: MENU_SETUP,
          path: PageMenuSetup,
        });
      }
      acc.push(menu);
      return acc;
    }, []);
  }, [menus, restaurantInfo]);

  const title = useMemo(() => {
    return getPageTitle(router.asPath, locale, menus);
  }, [router.asPath, locale, menus]);

  const handleMenuCollapse = useCallback(() => {
    dispatch({
      type: appActions.changeLayoutCollapsed,
      payload: !collapsed,
    });
  }, [collapsed]);

  if (!appReady) return null;

  const layout = (
    <Layout>
      {!isInApp && (
        <SiderMenu
          logo={collapsed ? SmallLogo : logo}
          menuData={filteredMenus}
          loadingMenu={loadingMenu}
          isMobile={!screens.sm}
          onCollapse={handleMenuCollapse}
          collapsed={collapsed}
          theme="light"
          fixedSideBar={fixedSideBar}
          width={sideBarWidth}
        />
      )}
      <Layout
        style={{ minHeight: 'calc(100vh)', paddingTop: !isInApp ? 64 : 0, backgroundColor: layoutBackgroundColor }}
      >
        {!isInApp && (
          <GlobalHeader
            isMobile={!screens.sm}
            sideBarWidth={sideBarWidth}
            collapsed={collapsed}
            onCollapse={handleMenuCollapse}
            showNavToggle={true}
          />
        )}
        <Content className={styles.content}>
          <ErrorBoundary>
            <AdminWaterMarker {...watermarkConfig} mergeStyle={{ minHeight: '80vh' }}>
              {props.children}
            </AdminWaterMarker>
          </ErrorBoundary>
        </Content>
      </Layout>
      <Fonts />
    </Layout>
  );

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <meta name="theme-color" content="#ce2c54" />
      </Head>
      {layout}
    </Fragment>
  );
};

export default BasicLayout;
