export function getSelectedKeys(menuData, matchedUrl) {
  return menuData.reduce((prev, current) => {
    if (matchedUrl.includes(current.path)) {
      prev.push(current.path);

      if (current.children) {
        const menu = current.children.find((_) => matchedUrl.includes(_.path));
        if (menu) prev.push(menu.path);
      }
    }

    return prev;
  }, []);
}

export function urlToList(url) {
  const urlList = url.split('/').filter((i) => i);
  return urlList.map((urlItem, index) => `/${urlList.slice(0, index + 1).join('/')}`);
}
