import React from 'react';
import { Drawer } from 'src/components/ChowBusComponents';
import SiderMenu from './SiderMenu';
import ClickOutsideHandlerWrapper from 'src/hooks/useClickOutside';

const SiderMenuWrapper = (props) => {
  const { isMobile, collapsed, onCollapse, width } = props;

  if (!isMobile) return <SiderMenu {...props} />;

  return (
    <ClickOutsideHandlerWrapper>
      <Drawer
        width={width}
        open={!collapsed}
        placement="left"
        onClose={() => onCollapse(true)}
        style={{
          padding: 0,
          height: '100vh',
        }}
      >
        <SiderMenu {...props} collapsed={false} />
      </Drawer>
    </ClickOutsideHandlerWrapper>
  );
};

export default React.memo(SiderMenuWrapper);
