import React, { Fragment } from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import Link from 'next/link';
import BaseMenu from './BaseMenu';
import styles from './index.less';
import { useSelector } from 'react-redux';
import { localeSelector } from 'src/selector/app';

const { Sider } = Layout;

const MenuPlaceholder = ({ width }) => (
  <div style={{ overflow: 'hidden', width, flex: `0 0 ${width}px`, minWidth: width, maxWidth: width }} />
);

const SiderMenu = (props) => {
  const { logo, collapsed, fixedSideBar, theme, width = 240, menuData, loadingMenu } = props;
  const _locale_ = useSelector(localeSelector);

  return (
    <Fragment key="SideMenu">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        width={width}
        theme={theme}
        className={classNames(styles.sider, { [styles.fixSiderBar]: fixedSideBar })}
      >
        <div className={styles.logo} id="logo">
          <Link href="/" locale={_locale_}>
            <a>
              <img src={logo} alt="logo" />
            </a>
          </Link>
        </div>
        <BaseMenu menuData={menuData} loadingMenu={loadingMenu} collapsed={collapsed} />
      </Sider>
      {fixedSideBar && <MenuPlaceholder width={collapsed ? 80 : width} />}
    </Fragment>
  );
};

export default React.memo(SiderMenu);
