import React from 'react';
import { Spin } from 'antd';

export default ({ absoluteCenter = false, background = '#fff', tip, height = '100%' }) => {
  if (absoluteCenter) {
    return (
      <div style={{ width: '100%', height, position: 'relative', background }}>
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin size="middle" />
        </div>
      </div>
    );
  }

  return <Spin size="middle" tip={tip} />;
};
