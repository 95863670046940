import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { Menu } from 'antd';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import PageLoading from '../PageLoading';
import { generateNavMenuData } from 'src/utils/tree';
import { getSelectedKeys, urlToList } from './utils';
import { localeSelector } from 'src/selector/app';
import { getSessionItem, KEYS, setSessionItem } from 'src/store/storage';
import styles from './index.less';

/**
 * 导航菜单
 * @param {Object} props
 * @param {Menu[]} props.menuData 导航菜单数据
 * @param {Boolean} props.loadingMenu 是否加载菜单数据中
 * @param {Boolean} props.collapsed 导航菜单是否收缩
 * @returns React.FC
 */
const BaseMenu = (props) => {
  const containerRef = useRef();
  const router = useRouter();
  const locale = useSelector(localeSelector);
  const [selectedKeys, setSelectedKeys] = useState(() => []);
  const [openKeys, setOpenKeys] = useState([]);
  const { menuData, loadingMenu, collapsed } = props;

  const menuItems = useMemo(() => {
    return generateNavMenuData({ navMenu: [], rawMenuData: menuData, locale });
  }, [menuData, locale]);

  useEffect(() => {
    let { pathname } = router;
    const matchedUrl = urlToList(pathname);
    const selectedKeys = getSelectedKeys(menuData, matchedUrl);
    const { length } = selectedKeys;
    if (!length) return;
    let openKeys = [];
    if (!collapsed) {
      openKeys = length > 1 ? [selectedKeys[0]] : [];
    }
    setOpenKeys(openKeys);
    setSelectedKeys([selectedKeys[length - 1]]);
  }, [router.pathname, menuData, collapsed]);

  useEffect(() => {
    // use timeout to execute scroll in next frame
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = parseInt(getSessionItem(KEYS.navMenuScrollTop, 0));
      }
    }, 0);
  }, [router.pathname]);

  const handleOpenChange = useCallback(
    (openKeys) => {
      const { length } = openKeys;
      setOpenKeys(length > 1 ? [openKeys[length - 1]] : openKeys);
    },
    [menuData]
  );

  const handleScroll = debounce(() => {
    setSessionItem({
      [KEYS.navMenuScrollTop]: containerRef.current.scrollTop,
    });
  }, 150);

  return (
    <div className={styles.menuWrapper} ref={containerRef} onScroll={handleScroll}>
      {loadingMenu ? (
        <PageLoading absoluteCenter />
      ) : (
        <Menu
          motion={{}}
          multiple={false}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          selectedKeys={selectedKeys}
          getPopupContainer={() => typeof document !== 'undefined' && document.body}
          items={menuItems}
        />
      )}
    </div>
  );
};

export default React.memo(BaseMenu);
